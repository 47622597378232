import {IEvent, ISearchIndexData} from "../data/interfaces";
import {buildEventTitle} from "./buildEventTitle";
import {convertDateRangeToPrettyString} from "./convertDateRangeToPrettyString";

/**
 * Generates search index data from an event.
 *
 * @param {IEvent} event - The event object containing details to be indexed.
 * @returns {ISearchIndexData} The search index data including title, crew, and date range.
 */
export function getSearchIndexData(event: IEvent): ISearchIndexData {
  return {
    title: buildEventTitle(event),
    subtitle: buildSubtitle(event),
    dateRange: convertDateRangeToPrettyString(event.start, event.end),
  };
}

/**
 * Constructs the subtitle for the search index data based on event details.
 *
 * @param {IEvent} event - The event object containing details.
 * @returns {string} The constructed subtitle.
 */
function buildSubtitle(event: IEvent): string {
  if (event.department === "all") {
    return event.eventType || "unknown";
  }

  const crewName = event.eventType !== "Team Meeting"
    ? `${event.crew?.preferredName || "unassigned"} - `
    : "";

  return `${event.department} - ${crewName}${event.eventType}`;
}