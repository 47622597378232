import React, {useEffect, useState} from 'react';
import {Badge, Button, Col, Form, Modal, Row} from 'react-bootstrap';
import {Department, IConflictCheckResponse, IEmployee, IEvent, ITraveler} from '../data/interfaces';
import {fetchTravelers} from '../data/fetchTravelers';
import {db} from '../firebaseConfig';
import {collection, doc} from 'firebase/firestore';
import {eventColors, EventType, getColor} from '../utils/eventColors';
import {formatDateForInput} from "../utils/formatDateForInput";
import {buildEventTitle} from "../utils/buildEventTitle";
import {userCanEdit} from "../utils/permissions";
import {getEmployeeFromUid} from "../data/getEmployeeFromUid";
import Select from 'react-select';

interface EventModalProps {
  event: IEvent | null;
  show: boolean;
  handleCloseModal: () => void;
  handleSave: (event: IEvent) => void;
  handleDuplicate: (event: IEvent) => void;
  checkForConflicts: (event: IEvent) => IConflictCheckResponse;
  department: Department;
  availableCrews: IEmployee[];
  deleteEvent: (event: IEvent) => void;
  employeeData: IEmployee | null;
  travelerId?: string; // Add this line
  onClose: () => void;
}

const EventModal: React.FC<EventModalProps> = ({
                                                 event,
                                                 show,
                                                 handleCloseModal,
                                                 handleDuplicate,
                                                 handleSave,
                                                 checkForConflicts,
                                                 department,
                                                 availableCrews,
                                                 deleteEvent,
                                                 employeeData,
                                                 travelerId,
                                               }) => {

  // State for the local event; initializes with the 'event' prop or default values
  const [localEvent, setLocalEvent] = useState<IEvent>(
    event || {
      id: '',
      airtableRecordId: '',
      start: '',
      end: '',
      crew: null,
      department: department,
      traveler: null,
      notes: '',
      customText: '',
      eventType: null,
      color: '',
    }
  );

  // State to hold travelers fetched from Firestore
  const [travelers, setTravelers] = useState<ITraveler[]>([]);
  useEffect(() => {
    if (travelerId) {
      const selectedTraveler = travelers.find(traveler => traveler.id === travelerId);
      setLocalEvent(prevState => ({
        ...prevState,
        traveler: selectedTraveler || null,
      }));
    }
  }, [travelerId, travelers]);
  /**
   * Synchronize 'localEvent' state with 'event' prop when 'event' changes.
   */
  useEffect(() => {
    if (show && !event) {
      // Reset the localEvent state when the modal is opened for a new event
      setLocalEvent({
        id: '',
        airtableRecordId: '',
        start: '',
        end: '',
        crew: null,
        department: department,
        traveler: null,
        notes: '',
        customText: '',
        eventType: null,
        color: '',
      });
    } else if (event) {
      setLocalEvent(event);
    }
    // console.log('Local event state:', localEvent); // Log local event state
  }, [show, event, department]);

  /**
   * Fetch travelers whenever the modal is shown or when the department changes.
   */
  useEffect(() => {
    if (show) {
      const loadTravelers = async () => {
        try {
          const fetchedTravelers = await fetchTravelers(department);
          const sortedTravelers = fetchedTravelers.sort((a, b) =>
            a.displayName.localeCompare(b.displayName)
          );
          setTravelers(sortedTravelers);
        } catch (error) {
          console.error('Error fetching travelers:', error);
        }
      };
      loadTravelers();
    } else {
      // Optional: Reset travelers when the modal is closed
      setTravelers([]);
    }
  }, [show, department]);

  /**
   * Handle changes in form inputs for simple fields.
   */
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {name, value} = event.target;
    setLocalEvent((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  /**
   * Adding traveler option to the select dropdown
   */
  const travelerOptions = travelers.map((traveler) => ({
    value: traveler.id,
    label: traveler.displayName,
  }));

  /**
   * Handle changes in form inputs for the traveler dropdown.
   */
  const handleReactSelectChange = (newValue: { value: string; label: string } | null) => {
    if (newValue) {
      const syntheticEvent = {
        target: {
          value: newValue.value,
        },
      } as React.ChangeEvent<HTMLSelectElement>; // Cast to the correct type
      handleTravelerChange(syntheticEvent);
    } else {
      const syntheticEvent = {
        target: {
          value: "",
        },
      } as React.ChangeEvent<HTMLSelectElement>;
      handleTravelerChange(syntheticEvent);
    }
  };

  /**
   * Handle traveler selection.
   * Updates the 'traveler' field in 'localEvent' based on selected traveler ID.
   */
  const handleTravelerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTravelerId = e.target.value;
    const selectedTraveler = travelers.find(
      (traveler) => traveler.id === selectedTravelerId
    );
    setLocalEvent((prevState) => ({
      ...prevState,
      traveler: selectedTraveler || null,
    }));
  };

  /**
   * Handle crew selection.
   * Updates the 'crew' field in 'localEvent' based on selected crew member ID.
   */
  const handleCrewChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCrewId = e.target.value;
    const selectedCrew = availableCrews.find(
      (crewMember) => crewMember.id === selectedCrewId
    );
    setLocalEvent((prevState) => ({
      ...prevState,
      crew: selectedCrew || null,
      resourceId: selectedCrew?.preferredName || 'undefined',
    }));
  };

  const handleEventTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedEventType = e.target.value as EventType;
    const {backgroundColor} = getColor(selectedEventType);

    setLocalEvent((prevState) => ({
      ...prevState,
      eventType: selectedEventType,
      color: backgroundColor,
      department: selectedEventType === "Holiday" ? 'all' : department, // Set department to 'all' for holidays
      traveler:
        selectedEventType === "Holiday"
        || selectedEventType === "No PO (pending)"
        || selectedEventType === "Day Off"
        || selectedEventType === "Team Meeting"
          ? null
          : prevState.traveler, // Clear traveler for holidays
      crew:
        selectedEventType === "Holiday"
        || selectedEventType === "Team Meeting"
          ? null
          : prevState.crew, // Clear crew for holidays
    }));
  };

  /**
   * Handle form submission.
   * Generates a new ID if necessary and invokes 'handleSave' and 'handleCloseModal'.
   */
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!userCanEdit(localEvent.start, employeeData)) {
      alert('You do not have permission to save or edit this event.');
      return;
    }

    // Check for event conflicts before saving
    const {safeToSave, lastModifiedBy} = checkForConflicts(localEvent);

    if (!safeToSave) {
      const employee: IEmployee | null = lastModifiedBy ? await getEmployeeFromUid(lastModifiedBy.id) : null;
      alert(`This event has been modified by ${employee?.preferredName ?? 'someone else'} since you started editing it. Your changes will not be saved.`);
      handleCloseModal();
      return;
    }

    // ensure that events have a meaningful title before saving
    if (buildEventTitle(localEvent).length < 3) {
      alert('Please choose a traveler or set custom text before saving!');
      return;
    }

    // Generate a new ID if this is a new event
    if (!localEvent.id) {
      const newDocRef = doc(collection(db, '_events'));
      localEvent.id = newDocRef.id;
    }

    handleSave(localEvent);
    handleCloseModal();
  };

  /**
   * Handle deletion of the event.
   */
  const handleDelete = () => {

    if (!userCanEdit(localEvent.start, employeeData)) {
      alert('You do not have permission to delete this event.');
      return;
    }

    if (event && window.confirm('Are you sure you want to delete this event? This action cannot be undone.')) {
      deleteEvent(event);
      handleCloseModal();
    }
  };

  const localHandleDuplicate = async () => {
    if (!localEvent) return;

    // Check if the user can duplicate the event
    if (!userCanEdit(localEvent.start, employeeData)) {
      alert('You do not have permission to duplicate this event.');
      return;
    }

    // Save the current event
    handleSave(localEvent);

    // Create a new event object based on the saved event
    const duplicatedEvent: IEvent = {
      ...localEvent,
      id: '', // Placeholder for the new ID
    };

    // Generate a new ID for the duplicated event
    const newDocRef = doc(collection(db, '_events'));
    duplicatedEvent.id = newDocRef.id;

    // Pass the duplicated event to the parent component
    handleDuplicate(duplicatedEvent);

    // Close the modal
    handleCloseModal();
  };
// console.log('localEvent', localEvent);
  return (
    <Modal show={show} onHide={handleCloseModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {/*Custom style added to vertically align the badge with the center of the title. Boostrap mt-1 was too much (0.25rem).*/}
          {!localEvent.id && <Badge bg="success" className="me-2 float-start" style={{marginTop: "0.2rem"}}>New</Badge>}
          <span>{buildEventTitle(localEvent)}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {/* Display traveler status if editing an existing event */}
          {localEvent.id && (
            <Form.Group className="mb-3">
              <Form.Label>
                Traveler Status: {localEvent.traveler?.status || 'N/A'}&nbsp;
                {localEvent.traveler?.portalLink && (
                  <a href={localEvent.traveler.portalLink} target="_blank" rel="noopener noreferrer">
                    View in Portal
                  </a>
                )}
              </Form.Label>
            </Form.Group>
          )}
          <Row>
            <Col>
              <Form.Group controlId="startDate" className="mb-3">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  name="start"
                  value={formatDateForInput(localEvent.start)}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="endDate" className="mb-3">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  name="end"
                  value={
                    localEvent.end ? formatDateForInput(localEvent.end) : ''
                  }
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            {/* Event Type Dropdown */}
            <Col>
              <Form.Group controlId="eventType" className="mb-3">
                <Form.Label>Event Type</Form.Label>
                <Form.Select
                  value={localEvent.eventType || ''}
                  onChange={handleEventTypeChange}
                  required
                  style={{
                    backgroundColor: getColor(localEvent.eventType).backgroundColor,
                    color: getColor(localEvent.eventType).textColor,
                  }}
                >
                  <option value="">Select Event Type</option>
                  {Object.keys(eventColors).map((eventType) => (
                    <option
                      key={eventType}
                      value={eventType}
                      style={{
                        backgroundColor: eventColors[eventType as EventType].backgroundColor,
                        color: eventColors[eventType as EventType].textColor,
                      }}
                    >
                      {eventType}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            {/* Crew Leader Dropdown */}
            {localEvent.eventType !== 'Holiday' && localEvent.eventType !== 'Team Meeting' &&
                <Col>
                  <Form.Group controlId="crew" className="mb-3">
                    <Form.Label>Crew Leader</Form.Label>
                    <Form.Select
                        value={localEvent.crew?.id || ''}
                        onChange={handleCrewChange}
                    >
                      <option value="">Select a Crew Leader</option>
                      {availableCrews
                        .sort((a, b) =>
                          a.preferredName.localeCompare(b.preferredName)
                        )
                        .map((crewMember) => (
                          <option key={crewMember.id} value={crewMember.id}>
                            {crewMember.preferredName}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
            }
          </Row>

          {/* Traveler Dropdown */}
          {localEvent.eventType !== 'Holiday' && localEvent.eventType !== 'Day Off' && localEvent.eventType !== 'Team Meeting' && localEvent.eventType !== 'No PO (pending)' &&
              <Form.Group controlId="traveler" className="mb-3">
                <Form.Label>Traveler</Form.Label>
                <Select
                    value={travelerOptions.find(option => option.value === localEvent.traveler?.id) || null}
                    onChange={handleReactSelectChange}
                    options={travelerOptions}
                    placeholder="Select a Traveler"
                    isClearable
                    isSearchable
                />
              </Form.Group>
          }

          <Form.Group controlId="customText" className="mb-3">
            <Form.Label>Custom Text (added to title)</Form.Label>
            <Form.Control
              as="textarea"
              name="customText"
              value={localEvent.customText || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="notes" className="mb-3">
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as="textarea"
              name="notes"
              value={localEvent.notes || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Row className="mt-3">
            <Col className="text-start">
              {localEvent.id &&

                  <>
                    <Button variant="outline-danger" className="me-2" onClick={handleDelete}>
                      Delete
                    </Button>
                    <Button variant="outline-success" onClick={localHandleDuplicate}>
                      Save & Duplicate
                    </Button>
                  </>
              }
            </Col>
            <Col className="text-end">
              <Button
                variant="outline-primary"
                onClick={handleCloseModal}
                className="me-2"
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EventModal;
