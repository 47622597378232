import axios from 'axios';
import { db } from "../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";

type AllowedSendFrom =
  | "portal@pwiworks.com"
  | "automations@pwiworks.com"
  | "live-pricing@pwiworks.com"
  | "marketing@pwiworks.com";

/**
 * Interface representing the structure of the email data.
 */
export interface IEmailData {
  to: string[];
  cc?: string[];
  bcc?: string[];
  from?: {
    name?: string;
    email?: AllowedSendFrom;
  };
  subject: string;
  body: string;
  replyTo?: string;
  cta?: {
    text: string;
    url: string;
  };
}

/**
 * Fetches the API key and webhook URL from Firestore.
 *
 * @returns {Promise<{ apiKey: string, webhookUrl: string }>} A promise that resolves to an object containing the API key and webhook URL.
 */
async function getAPIData(): Promise<{ apiKey: string, webhookUrl: string }> {
  // Fetch API key from secure location
  const docRef = doc(db, 'env/emailApi');
  const docData = await getDoc(docRef);
  return docData.data() as { apiKey: string, webhookUrl: string };
}

/**
 * Sends an email using the provided email data.
 *
 * @param {IEmailData} emailData - The data of the email to be sent.
 * @returns {Promise<void>} A promise that resolves when the email is sent.
 */
export async function sendEmail(emailData: IEmailData): Promise<void> {
  try {
    const { apiKey, webhookUrl } = await getAPIData();
    const response = await axios.post(webhookUrl, emailData, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': apiKey,
      }
    });

    if (response.status === 200 || response.status === 201) {
      console.log('Email sent successfully');
    } else {
      console.error('Unexpected response status', response.status, response.data);
    }
  } catch (error) {
    console.error('Failed to send email', error);
  }
}
